function initBannerSliders() {
	//Banner slider
	$('.banner-slider').slick({
		autoplay: false,
		autoplaySpeed: 5000,
		dots: true,
		arrows: true,
		infinite: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		zIndex: 2,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					arrows: false
				}
			},
			{
				breakpoint: mobile_size,
				settings: {
					arrows: false,
					dots: false
				}
			}
		]
	})
}

function initStorySlider() {
	//Our Story slider

	$('.story-slider').slick({
		autoplay: false,
		arrows: true,
		infinite: false,
		speed: 600,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: tablet_size,
				settings: 'unslick'
			}
		]
	})
}

function initInspirationSlider() {
	$('.inspiration-slider').slick({
		autoplay: false,
		arrows: true,
		dots: true,
		infinite: false,
		speed: 600,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: desktop_l_size,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: mobile_l_size,
				settings: 'unslick'
			}
		]
	})
}
function initSliders() {
	var carousels = $('.carousel'),
		options = {
			autoplay: false,
			arrows: true,
			dots: true,
			infinite: false,
			speed: 600,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: desktop_l_size,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: tablet_size,
					settings: {
						slidesToShow: 1
					}
				}
			]
		}

	carousels.each(function () {
		$(this).on('init', function (event) {
			$.event.trigger({
				type: 'sliderInit',
				elem: $(this)
			})
			$(this).addClass('is--visible')
		})

		$(this).slick(options)
	})
}
