function setupTabs() {
	// $('#tabs-nav li:first-child').addClass('is-active')
	// $('.tab-content__item:first').addClass('is-open')

	$('[data-tab]').each(function () {
		$(this)
			.find('.tab-link')
			.on('click', function () {
				$('.tab-link').removeClass('is-active')
				$(this).addClass('is-active')
				$('.tab-content__item').removeClass('is-open')

				var activeTab = $(this).data('tabOpen')
				$(activeTab).addClass('is-open')
				return false
			})
	})

	// $('.accordion-tabs-minimal').each(function (index) {
	// 	$(this).children('li').first().children('.tab-link').addClass('is-active').next().addClass('is-open').show()
	// })

	// $('.accordion-tabs-minimal').on('click', 'li > .tab-link', function (event) {
	// 	event.preventDefault()

	// 	if ($('.recipe-instructions').length == 0 || ($(window).width() <= 480 && $('.recipe-instructions').length > 0)) {
	// 		if (!$(this).hasClass('is-active')) {
	// 			var accordionTabs = $(this).closest('.accordion-tabs-minimal')
	// 			accordionTabs.find('.is-open').removeClass('is-open').hide()

	// 			$(this).next().toggleClass('is-open').toggle()
	// 			accordionTabs.find('.is-active').removeClass('is-active')
	// 			$(this).addClass('is-active')
	// 		}
	// 	}
	// })
}

function equalHeights(elems, ignoreInlineHeight) {
	var col_height = 0

	elems.each(function () {
		if (ignoreInlineHeight) {
			$(this).height('auto')
		}

		if ($(this).height() > col_height && !$(this).hasClass('follower-col')) {
			col_height = $(this).innerHeight()
		}
	})

	elems.css('height', col_height + 'px')
}

function setupContactTabs() {
	if (window.location.hash) {
		//Check if page URL has anchor link
		setActiveContactLink($('a[href="' + window.location.hash + '"]'))
		showContactTab(window.location.hash)
	}

	$('.contact-info nav a').click(function (e) {
		e.preventDefault()
		setActiveContactLink($(this))
		showContactTab($(this).attr('href'))

		if ($('#mobileCountrySwitcher').is(':visible')) {
			mobileCountrySwitcher($('#mobileCountrySwitcher'), 'hide')
		}
	})

	$('#mobileCountrySwitcher').click(function (e) {
		e.preventDefault()

		if (!$(this).hasClass('active')) {
			mobileCountrySwitcher($(this), 'show')
		} else {
			mobileCountrySwitcher($(this), 'hide')
		}
	})
}

function setActiveContactLink(link) {
	$('.contact-info nav a').removeClass('active')
	link.addClass('active')
}

function showContactTab(tab) {
	$('.contact-info__details__country').hide() //Hide all tabs
	$(tab).fadeIn() //Show correct tab
	window.location = tab //Update location bar with tab id
	$('html, body').scrollTop(0) //Prevent page scrolling down
}

function mobileCountrySwitcher(button, action) {
	if (action == 'show') {
		button.addClass('active')
		$('.site-footer').css('z-index', '-1')
		$('.contact-info nav').slideDown('fast')
	} else {
		button.removeClass('active')
		$('.contact-info nav').slideUp('fast', function () {
			$(this).removeAttr('style')
			$('.site-footer').css('z-index', '0')
		})
	}
}
