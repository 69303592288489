function searchClickListeners() {
	$('#nav-search, #nav-search-mobile, #search-overlay-close, #mobile-menu-search').click(function (e) {
		e.preventDefault()

		$('#search_overlay').fadeToggle('slow', function () {
			$('body').toggleClass('noscroll')
			if ($(this).is(':visible')) {
				$('#nav-search-text').focus()
				$('#nav-search, #nav-search-mobile, #search-overlay-close, #mobile-menu-search').attr('aria-expanded', 'true')
			} else {
				$('#nav-search, #nav-search-mobile, #search-overlay-close, #mobile-menu-search').attr('aria-expanded', 'false')
			}
		})
	})
}

function algoliaInit() {
	var client = algoliasearch('XGWP0871NC', '1c6543f828f6b819e8583d4f5d984195')

	// Test Algolia account
	//var client = algoliasearch("Z3XSWT9505", "faf901ccfcce885c135dd451685dc19b");

	var recipes = client.initIndex('pataks_recipes')
	var products = client.initIndex('pataks_products')
	var pages = client.initIndex('pataks_pages')

	var templateRecipes = Hogan.compile(
		'<div class="card--search">' +
			'<div class="card-table">' +
			'<div class="card__img card__img--search" style="{{ background_img }}">' +
			'<a href="{{ url }}" class="overlay"></a>' +
			'</div>' +
			'<div class="card__text card__text--search">' +
			'<a href="{{ url }}" class="overlay" tabindex="-1"></a>' +
			'<div class="card__text__desc add-elipsis">' +
			'<h3><a href="{{ url }}" class="check-font">{{{ title }}}</a></h3>' +
			'<p>{{{ intro }}}</p>' +
			'</div>' +
			'<div class="card__text__links">' +
			'<a href="{{ url }}" class="read-more">More Details<span class="fa fa-chevron-right" aria-hidden="true"></span></a>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</div>'
	)

	var templateProducts = Hogan.compile(
		'<div class="card--search">' +
			'<div class="card-table">' +
			'<div class="card__img card__img--search {{ category }}">' +
			'<a href="{{ url }}" class="overlay"><img src="{{ image }}" alt="" /></a>' +
			'</div>' +
			'<div class="card__text card__text--search">' +
			'<a href="{{ url }}" class="overlay" tabindex="-1"></a>' +
			'<div class="card__text__desc add-elipsis">' +
			'<h3><a href="{{ url }}">{{{ name }}}</a></h3>' +
			'<p>{{{ description }}}</p>' +
			'</div>' +
			'<div class="card__text__links">' +
			'<a href="{{ url }}" class="read-more">More Details<span class="fa fa-chevron-right" aria-hidden="true"></span></a>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</div>'
	)

	var templatePages = Hogan.compile(
		'<div class="card--search">' +
			'<div class="card-table">' +
			'<div class="card__img card__img--search" style="{{ background_img }}">' +
			'<a href="{{ url }}" class="overlay"></a>' +
			'</div>' +
			'<div class="card__text card__text--search">' +
			'<a href="{{ url }}" class="overlay" tabindex="-1"></a>' +
			'<div class="card__text__desc add-elipsis">' +
			'<h3><a href="{{ url }}">{{{ title }}}</a></h3>' +
			'<p>{{{ intro }}}</p>' +
			'</div>' +
			'<div class="card__text__links">' +
			'<a href="{{ url }}" class="read-more">More Details<span class="fa fa-chevron-right" aria-hidden="true"></span></a>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</div>'
	)

	var templateDropdown = Hogan.compile(
		'<div class="cards-wrap aa-dataset-products"> </div>' +
			'<div class="cards-wrap aa-dataset-recipes"> </div>' +
			'<div class="cards-wrap aa-dataset-pages"> </div>'
	)

	autocomplete(
		'#nav-search-text',
		{
			hint: false,
			debug: true,
			templates: {
				dropdownMenu: templateDropdown.render()
			}
		},
		[
			{
				source: autocomplete.sources.hits(recipes, { hitsPerPage: 6, filters: 'active=1' }),
				displayKey: 'name',
				name: 'recipes',
				templates: {
					header: '<h2 class="section text-shadow" data-text-shadow="Recipes">Recipes</h2>',
					suggestion: function (hit) {
						hit.background_img =
							hit.image !== null
								? 'background-image:url(' + hit.image.replace('/uploads/', '/uploads/400/400/') + ')'
								: 'background-image:url(/build/images/recipe_placeholder.jpg)'
						return templateRecipes.render(hit)
					}
				}
			},
			{
				source: autocomplete.sources.hits(products, { hitsPerPage: 3, filters: 'active=1' }),
				displayKey: 'name',
				name: 'products',
				templates: {
					header: '<h2 class="section text-shadow" data-text-shadow="Products">Products</h2>',
					suggestion: function (hit) {
						return templateProducts.render(hit)
					}
				}
			},
			{
				source: autocomplete.sources.hits(pages, { hitsPerPage: 3, filters: 'inspiration=1' }),
				displayKey: 'name',
				name: 'pages',
				templates: {
					header: '<h2 class="section text-shadow" data-text-shadow="Inspirations" >Inspirations</h2>',
					suggestion: function (hit) {
						hit.background_img =
							hit.image_url !== null
								? 'background-image:url(' + hit.image_url.replace('/uploads/', '/uploads/400/400/') + ')'
								: 'background-image:url(/build/images/recipe_placeholder.jpg)'
						return templatePages.render(hit)
					}
				}
			}
		]
	).on('autocomplete:updated', function (e, s, d) {
		changeNumberFont() // Wrap each word in span to style numbers
	})
}
