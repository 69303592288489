function setTextShadow() {
	var textShadow = [].slice.call(document.querySelectorAll('.text-shadow'))

	if (!textShadow) return

	for (var i = 0; i < textShadow.length; i++) {
		const text = textShadow[i].innerText,
			hasNumbers = /\d+/g.test(text)

		if (hasNumbers) {
			textShadow[i].innerHTML = _handleNumbersInText(text, true)
		} else {
			textShadow[i].setAttribute('data-text-shadow', textShadow[i].innerText)
		}
	}
}

function changeNumberFont() {
	var checkFont = [].slice.call(document.querySelectorAll('.check-font'))
	if (!checkFont) return

	for (var i = 0; i < checkFont.length; i++) {
		const text = checkFont[i].innerText,
			hasNumbers = /\d+/g.test(text)
		if (hasNumbers) {
			checkFont[i].innerHTML = _handleNumbersInText(text, false)
		}
	}
}

function _handleNumbersInText(string, withTextShadowDataset) {
	var split = string.split(' ')

	var html = []
	for (var i = 0; i < split.length; i++) {
		var dataset = withTextShadowDataset ? ' data-text-shadow=' + split[i] : '',
			open = '<span' + dataset + '>',
			close = '</span>'

		if (/^\d+$/.test(split[i])) {
			open = '<span ' + dataset + ' class="number">'
		}

		html.push(open + split[i] + close)
	}
	return html.join(' ')
}
