/*=== Setup Object.assign if not supported  ===*/
if (!Object.assign) {
	Object.defineProperty(Object, 'assign', {
		enumerable: false,
		configurable: true,
		writable: true,
		value: function (target) {
			'use strict'
			if (target === undefined || target === null) {
				throw new TypeError('Cannot convert first argument to object')
			}

			var to = Object(target)
			for (var i = 1; i < arguments.length; i++) {
				var nextSource = arguments[i]
				if (nextSource === undefined || nextSource === null) {
					continue
				}
				nextSource = Object(nextSource)

				var keysArray = Object.keys(Object(nextSource))
				for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
					var nextKey = keysArray[nextIndex]
					var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey)
					if (desc !== undefined && desc.enumerable) {
						to[nextKey] = nextSource[nextKey]
					}
				}
			}
			return to
		}
	})
}
/*=== END Object.assign  ===*/

var desktop_xxxl_size = 1920,
	desktop_xxl_size = 1640,
	desktop_xl_size = 1440,
	desktop_l_size = 1280,
	desktop_size = 1024,
	story_slider_bp = 910,
	tablet_size = 768,
	mobile_l_size = 640,
	mobile_size = 480

var $grid = ''

var isMobile = false //initiate as false
// device detection
if (
	/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
		navigator.userAgent
	) ||
	/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
		navigator.userAgent.substr(0, 4)
	)
)
	isMobile = true

$(document).ready(function () {
	//DOM is loaded
	$('html').removeClass('no-js').addClass('js')

	setTextShadow()
	headerBackground()

	initBannerSliders() //Initiate banner sliders
	initStorySlider() //Our Story page
	initSliders()
	initInspirationSlider()

	setupTabs() //Initiate tabs

	portionChangeListener() //Portion selector handler

	mobileNavIconListeners() //Click listeners for burger icon

	avoidOrphans('.featured')

	if ($('.site-header').length > 0 && $('section').length > 0) {
		//Only listen for scroll on full pages (i.e. not on Pattern Lab component pages)
		setupNavScroll() //Nav scroll listener
	}

	$('.add-elipsis').dotdotdot({
		height: 64,
		fallbackToLetter: true,
		watch: true
	}) //Add ellipsis where card heading and/or description is too long

	$('.add-elpisis--categories').dotdotdot({
		height: 72,
		fallbackToLetter: true,
		watch: true
	})

	showVideoClick() //Fade in video on click
	recipeVideoClick() //Recipe header video

	searchClickListeners()

	algoliaInit() //Main Nav Algolia Search

	if ($('#recipe_results').length > 0) {
		recipeFilterInit() //Recipe Algolia filter
	}

	//Social Sharing Bar
	if ($('#social_share').length > 0) {
		var facebook_share = {
			share: 'facebook',
			shareUrl: 'https://www.facebook.com/sharer/sharer.php?u={url}'
		}

		var twitter_share = {
			share: 'twitter',
			via: 'PataksUK',
			text: document.title
		}

		var pinterest_share = {
			share: 'pinterest',
			shareIn: 'blank',
			logo: 'fa fa-pinterest-p'
		}

		var whatsapp_share = {
			share: 'whatsapp',
			text: document.title
		}

		$('#social_share').jsSocials({
			shares: [facebook_share, twitter_share, pinterest_share, whatsapp_share],
			showLabel: false,
			showCount: false,
			shareIn: 'popup'
		})
		if (isMobile) {
			//Show WhatsApp on mobile only
			$('.jssocials-share-whatsapp').css('display', 'inline-block')
		}
	}

	if ($('.contact-info').length > 0) {
		setupContactTabs()
	}
})

$(window).load(function () {
	//Images, CSS etc have are loaded

	//Cards grid
	$grid = $('.autofit').isotope({
		layoutMode: 'packery',
		packery: {
			gutter: '.gutter-sizer'
		},
		itemSelector: '.card',
		percentPosition: true
	})

	$grid.on('arrangeComplete', function (event, laidOutItems) {
		//Handle empty results
		if (laidOutItems.length === 0) {
			$('#no-results').fadeIn('fast')
		}
	})

	filterEventListeners() //Cards filter

	if ($(window).width() > mobile_l_size) {
		//Columns become full width on large mobiles
		// equalHeights($('.product-col'), false) //Match heights of product/recipe columns
	}
})

$(window).resize(function () {
	//Fired on window resize or rotate

	if ($(window).width() > mobile_l_size) {
		//Update column heights
		// equalHeights($('.product-col'), true)
	}

	if ($(window).width() <= mobile_size && $('.recipe-instructions').length > 0) {
		//Recipe columns become tabs on mobile
		setupTabs()
	}
})

///////////////////// Temp jamie oliver video js ////////////////////////////
$(document).ready(function () {
	// $( "div#holder-tc-vid-1" ).html( "<video name=\"tc-vid-1\" id=\"tc-vid-1\" width=\"960\" height=\"540\">\n" +
	//     "                <source type=\"video/mp4\" src=\"build/images/jamie/videos/tc-1.mp4\" />\n" +
	//     "                <track label=\"English\" kind=\"subtitles\" srclang=\"en\" src=\"build/images/jamie/videos/tc-1.vtt\" default>\n" +
	//     "            </video>" );

	for (i = 1; i <= 5; i++) {
		if ($(window).width() <= 560) {
			$('div#holder-tc-vid-' + i).html(
				'<video name="tc-vid-' +
					i +
					'" id="tc-vid-' +
					i +
					'" width="1000" height="1000">\n' +
					'                <source type="video/mp4" src="build/images/jamie/videos/m-tc-' +
					i +
					'.mp4" />\n' +
					'                <track label="English" kind="subtitles" srclang="en" src="build/images/jamie/videos/tc-' +
					i +
					'.vtt" default>\n' +
					'            </video>'
			)
		} else {
			$('div#holder-tc-vid-' + i).html(
				'<video name="tc-vid-' +
					i +
					'" id="tc-vid-' +
					i +
					'" width="960" height="540">\n' +
					'                <source type="video/mp4" src="build/images/jamie/videos/tc-' +
					i +
					'.mp4" />\n' +
					'                <track label="English" kind="subtitles" srclang="en" src="build/images/jamie/videos/tc-' +
					i +
					'.vtt" default>\n' +
					'            </video>'
			)
		}
	}

	///////////////////////// heritage //////////////////////////////
	if ($(window).width() <= 560) {
		$('div#jamie-heritage').html(
			'<video class="afterglow" id="heritage" width="1000" height="1000" poster="/build/images/jamie/videos/p-heritage.jpeg">\n' +
				'                        <source type="video/mp4" src="/build/images/jamie/videos/m-heritage.mp4" /> <track label="English" kind="subtitles" srclang="en" src="/build/images/jamie/videos/heritage.vtt" default>\n' +
				'                    </video>'
		)
	} else {
		$('div#jamie-heritage').html(
			'<video class="afterglow" id="heritage" width="1280" height="720" poster="/build/images/jamie/videos/p-heritage.jpeg">\n' +
				'                        <source type="video/mp4" src="/build/images/jamie/videos/heritage.mp4" /> <track label="English" kind="subtitles" srclang="en" src="/build/images/jamie/videos/heritage.vtt" default>\n' +
				'                    </video>'
		)
	}
	/////////////////////////////////////////////////////////////////

	///////////////////////// AubergineRoganJosh //////////////////////////////
	if ($(window).width() <= 560) {
		$('div#AubergineRoganJosh-holder').html(
			'<video id="AubergineRoganJosh" width="1000" height="1000">\n' +
				'                        <source type="video/mp4" src="/build/images/jamie/recipes/m-AubergineRoganJosh.mp4"/>\n' +
				'                        <track label="English" kind="subtitles" srclang="en" src="/build/images/jamie/recipes/AubergineRogan.vtt" default>\n' +
				'                    </video>'
		)
	} else {
		$('div#AubergineRoganJosh-holder').html(
			'<video id="AubergineRoganJosh" width="960" height="540">\n' +
				'                        <source type="video/mp4" src="/build/images/jamie/recipes/AubergineRoganJosh.mp4"/>\n' +
				'                        <track label="English" kind="subtitles" srclang="en" src="/build/images/jamie/recipes/AubergineRogan.vtt" default>\n' +
				'                    </video>'
		)
	}
	/////////////////////////////////////////////////////////////////

	///////////////////////// CrispyKormaSalmon //////////////////////////////
	if ($(window).width() <= 560) {
		$('div#CrispyKormaSalmon-holder').html(
			'<video id="CrispyKormaSalmon" width="1000" height="1000">\n' +
				'                        <source type="video/mp4" src="/build/images/jamie/recipes/m-CrispyKormaSalmon.mp4"/>\n' +
				'                        <track label="English" kind="subtitles" srclang="en" src="/build/images/jamie/recipes/CrispyKormaSalmon.vtt" default>\n' +
				'                    </video>'
		)
	} else {
		$('div#CrispyKormaSalmon-holder').html(
			'<video id="CrispyKormaSalmon" width="960" height="540">\n' +
				'                        <source type="video/mp4" src="/build/images/jamie/recipes/CrispyKormaSalmon.mp4"/>\n' +
				'                        <track label="English" kind="subtitles" srclang="en" src="/build/images/jamie/recipes/CrispyKormaSalmon.vtt" default>\n' +
				'                    </video>'
		)
	}
	/////////////////////////////////////////////////////////////////

	///////////////////////// TikkaChickenSkewers //////////////////////////////
	if ($(window).width() <= 560) {
		$('div#TikkaChickenSkewers-holder').html(
			'<video id="TikkaChickenSkewers" width="1000" height="1000">\n' +
				'                        <source type="video/mp4" src="/build/images/jamie/recipes/m-TikkaChickenSkewers.mp4"/>\n' +
				'                        <track label="English" kind="subtitles" srclang="en" src="/build/images/jamie/recipes/TikkaSkewers.vtt" default>\n' +
				'                    </video>'
		)
	} else {
		$('div#TikkaChickenSkewers-holder').html(
			'<video id="TikkaChickenSkewers" width="960" height="540">\n' +
				'                        <source type="video/mp4" src="/build/images/jamie/recipes/TikkaChickenSkewers.mp4"/>\n' +
				'                        <track label="English" kind="subtitles" srclang="en" src="/build/images/jamie/recipes/TikkaSkewers.vtt" default>\n' +
				'                    </video>'
		)
	}
	/////////////////////////////////////////////////////////////////
})
/////////////////////////////////////////////////////////////////////////////
