// https://gist.github.com/Firestorm980/a705690f194249aea2b2
function avoidOrphans(classSelector) {
	$(classSelector).each(function () {
		var wordArrayRaw = $(this).html().split(' ') // Get an array of words
		var wordArray = wordArrayRaw.filter(function (n) {
			return n !== undefined && n !== ''
		}) // Filter out the bad

		// Are there enough words to work with?
		if (wordArray.length > 1) {
			// Exclude tags within the query
			if (wordArray[0].indexOf('<') < 0 || wordArray[wordArray.length - 1].indexOf('>') < 0) {
				wordArray[wordArray.length - 2] += '&nbsp;' // 2nd to last word, add a non-breaking space

				// Remove the last word from the array
				// We to this so that later when we "join", spaces are correct.
				var lastWord = wordArray.pop()

				// Create the final HTML
				var finalString = wordArray.join(' ') + lastWord

				// Replace the HTML with our new HTML
				$(this).html(finalString)
			}
		}
	})
}
