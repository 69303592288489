function portionChangeListener(){
	$(".portion-options").change(function(){
		
		var selected_serving = $(this).find(":selected").val();
		var target_ingredients_list = $(".serving-size[data-serves='"+selected_serving+"']");
		
		$(".serving-size").hide();
		target_ingredients_list.fadeIn("fast");
		
	});
	
}
