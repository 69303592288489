/* Accepts element to add class to, modifying class, min scroll down required to trigger collapse */
function changeScrollClass(elem, mod_class, this_scroll, min_scroll) {
	if (this_scroll > min_scroll && !elem.hasClass(mod_class)) {
		//Add collapsing class to element, i.e. make nav slim
		elem.addClass(mod_class)
	} else if (this_scroll < min_scroll && elem.hasClass(mod_class)) {
		//Remove collapsing class from element, i.e. restore nav
		elem.removeClass(mod_class)
	}
}

function setupNavScroll() {
	var section_after_nav = $('section:first-of-type')
	var min_scroll_dist = 100

	$(window).scroll(function (e) {
		var this_scrollTop = $(this).scrollTop()
		changeScrollClass($('.site-header'), 'site-header--slim', this_scrollTop, min_scroll_dist) //Collapse nav once first section bottom reaches page top
	})
}

function mobileNavIconListeners() {
	$('#nav-mobile-open').click(function (e) {
		e.preventDefault()
		$('#nav-mobile-open, #nav-mobile-close').attr('aria-expanded', 'true')
		$('.mobile-overlay').addClass('active')
	})

	$('#nav-mobile-close').click(function (e) {
		e.preventDefault()
		$('#nav-mobile-open, #nav-mobile-close').attr('aria-expanded', 'false')
		$('.mobile-overlay').removeClass('active')
	})

	$('.mobile-overlay').click(function (e) {
		if (e.target === $('.mobile-overlay.active')[0]) {
			$('#nav-mobile-open, #nav-mobile-close').attr('aria-expanded', 'false')
			$('.mobile-overlay').removeClass('active')
		}
	})

	$('.mobile-overlay .expand').click(function (e) {
		e.preventDefault()
		var $this = $(this)
		$this.next('ul').slideToggle('fast', function () {
			$this.find('.fa').toggleClass('fa-chevron-down').toggleClass('fa-chevron-up')
		})
	})
}
