function headerBackground() {
	var headerBackground = document.querySelector('.site-header__background'),
		headerBackgroundInner = document.querySelector('.site-header__background__inner'),
		docHeight = document.body.clientHeight

	window.addEventListener('resize', function (e) {
		docHeight = document.body.clientHeight
        if (headerBackgroundInner.style.height !== docHeight) headerBackgroundInner.style.height = docHeight * 2 + 'px'
	})

	document.addEventListener('scroll', function (e) {
		docHeight = document.body.clientHeight
		if (headerBackgroundInner.style.height !== docHeight) headerBackgroundInner.style.height = docHeight * 2 + 'px'
		vendorPrefixes(headerBackgroundInner, 'transform', 'translateY(-' + window.scrollY + 'px);')
		headerBackgroundInner.style.top = -window.scrollY + 'px'
	})

	headerBackgroundInner.style.height = docHeight * 2 + 'px'
}

function vendorPrefixes(element, property, value) {
	element.style['webkit' + property] = value
	element.style['moz' + property] = value
	element.style['ms' + property] = value
	element.style['o' + property] = value
}
