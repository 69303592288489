var parent = $('*[data-same-height-parent]'),
	chunkSize = 1,
	windowWidth = window.innerWidth

function applySameHeight(parent) {
	$(parent).each(function () {
		var textArray = $(this).find('[data-same-height-element]')
		if (haveDifferentClasses(textArray)) {
			// Return if the elements are different under one parent
			return false
		} else if (
			arrayContainsOneWhitelistedElement(
				['carousel', 'inspiration-slider', 'products'],
				$(this).attr('class').split(/\s+/)
			)
		) {
			// If using carousel, applies same height to every elements
			sameHeight(textArray)
		} else {
			// If using grid, applies same height to each row
			if (windowWidth >= desktop_size) chunkSize = 3
			else if (windowWidth >= mobile_l_size && windowWidth < desktop_size) chunkSize = 2
			else chunkSize = 1
			chunk(textArray, chunkSize)
		}
	})
}

//Fire on load by default
$(document).on('ready', function (e) {
	applySameHeight(parent)
})

$(window).on('resize', function (e) {
	windowWidth = window.innerWidth
	// Fire on resize to update values
	applySameHeight(parent)
})

$(document).on('sliderInit', function (e) {
	// Fire on slider init to get correct height once carousel has been set up
	applySameHeight(e.elem)
})

function chunk(array, amount) {
	for (var i = 0; i < array.length; i += amount) {
		var tempArray = array.slice(i, i + amount)
		sameHeight(tempArray)
	}
}

function sameHeight(array) {
	maxHeight = 0
	array.each(function (i) {
		p = $(this).find('[data-same-height-ref]')
		pHeight = $(this).find('[data-same-height-ref]').height()

		if (pHeight > maxHeight) {
			maxHeight = pHeight
		}

		// If for some reason, you need to fill the space by adapting the content to the parent
		// Yse data-default-to-parent="true" on the data-same-height-ref lement
		if (p.data('defaultToParent') === true) {
			parentHeight = $(this).parent().height()
			if (parentHeight > maxHeight) maxHeight = parentHeight
		}
	})

	array.each(function (i) {
		$(this).height(maxHeight)
	})
}
function haveDifferentClasses(classesArray) {
	var refClass,
		areDifferent = false

	$(classesArray).each(function () {
		if ($(this).attr('class')) {
			var firstClass = $(this).attr('class').split(/\s+/)[0]

			if (refClass === undefined) {
				refClass = firstClass
			} else if (refClass !== firstClass) {
				areDifferent = true
				return false
			}
		}
	})
	return areDifferent
}

function removeDifferentElement(classesArray) {
	var refClass,
		cleanArray = classesArray.slice() // clone array
	$(classesArray).each(function (index) {
		var firstClass = $(this).attr('class').split(/\s+/)[0]

		if (refClass === undefined) {
			refClass = firstClass
		} else if (refClass !== firstClass) {
			cleanArray.slice(index)
		}
	})

	return cleanArray
}

function arrayContainsOneWhitelistedElement(whitelist, array) {
	for (var i = 0; i < array.length; i++) {
		if ($.inArray(array[i], whitelist) > -1) return true
	}
}
