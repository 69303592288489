function filterEventListeners() {
	//Filter the grid on select/input change or reset click

	$('.filter__params select').change(function () {
		/*
	    if($('#load_more:visible')){
			$('#load_more').hide();
			$grid.isotope(); //Relayout Masonry
	    }
*/

		$('#no-results').fadeOut('fast', function () {
			handleSelectFilter($('.autofit'), $('.filter__params'))
		})
	})
	$('.filter__reset button').on('click touchstart', function (e) {
		e.preventDefault()
		$('#no-results').fadeOut('fast')
		resetSelectFilter($('.autofit'), $('.filter__params'))
	})

	$('.filter__inner label').on('click touchstart', function (e) {
		e.preventDefault()
		handleCheckboxFilter($('.autofit'), $('.filter__inner'), $(e.target))
	})
}

function handleSelectFilter(results_container, filter_container) {
	var filters = {}

	filter_container.find('select').each(function () {
		var group = $(this).attr('data-filter-group')
		var selected_val = $(this).find(':selected').attr('data-filter-value')
		if (selected_val !== '') {
			filters[group] = selected_val
		}
	})

	var isoFilters = []
	for (var prop in filters) {
		isoFilters.push(filters[prop])
	}

	var selector = isoFilters.join('')
	results_container.isotope({ filter: selector })
}

function resetSelectFilter(results_container, filter_container) {
	filter_container.find('option:selected').removeAttr('selected') //Reset select boxes

	results_container.isotope({
		//Clear filter
		filter: '*'
	})
}

function handleCheckboxFilter(results_container, filter_container, target_label) {
	var total_labels = filter_container.find('label').length,
		selected_labels = filter_container.find('label.selected').length,
		target_checkbox = target_label.prev("input[type='checkbox']"),
		isoFilters = []

	if (total_labels == selected_labels) {
		//All categories already selected, unselect all but clicked
		filter_container.find('label').not(target_label).removeClass('selected')
		filter_container.find("input[type='checkbox']").not(target_checkbox).prop('checked', false)
	} else if (selected_labels == 1 && target_label.hasClass('selected')) {
		filter_container.find('label').addClass('selected') //Label clicked is only one on, so switch on all
		filter_container.find("input[type='checkbox']").prop('checked', true)
	} else {
		if (target_label.hasClass('selected')) {
			target_label.removeClass('selected')
			target_checkbox.prop('checked', false)
		} else {
			target_label.addClass('selected')
			target_checkbox.prop('checked', true)
		}
	}

	filter_container.find("input[type='checkbox']").each(function () {
		if ($(this).is(':checked')) {
			isoFilters.push($(this).val())
		}
	})

	var selector = isoFilters.join(',')
	results_container.isotope({ filter: selector })
}

function cardsLoadMore(selector) {
	var show_items = 6,
		steps = 6
	total = $(selector).length

	$(selector).hide()
	$(selector + ':lt(' + show_items + ')').show()

	$('#load_more').click(function (e) {
		e.preventDefault()

		show_items += steps
		$(selector + ':lt(' + show_items + ')').show()

		if ($('.soft-load').hasClass('autofit')) {
			$grid.isotope() //Relayout Masonry
		}

		$('.card__text__desc.add-elipsis').dotdotdot({
			height: 64,
			fallbackToLetter: true,
			watch: true
		})

		if (show_items > total - (total % steps)) {
			//If last "page" hide button
			$('#load_more').hide()
		}
	})
}
