function showVideoClick() {
	$('#show-video, #show-video-inline').click(function (e) {
		e.preventDefault()
		$(this).parents('.video__cta').next('.hidden').fadeIn('slow')
		$(this).parents('.banner-slider__slide__inner').addClass('active')
	})
}

function recipeVideoClick() {
	$('.btn-play').click(function (e) {
		e.preventDefault()
		$(this).next('.video-container').addClass('active')
	})
}
