function recipeFilterInit() {
	var mobile_filter_close =
		'<button type="button" role="button" aria-label="Close Filter" id="close-filter">Done</button>'

	var APPLICATION_ID = 'XGWP0871NC'
	var SEARCH_ONLY_API_KEY = '1c6543f828f6b819e8583d4f5d984195'
	var INDEX_NAME = 'pataks_recipes'
	var PARAMS = {
		hitsPerPage: 6,
		maxValuesPerFacet: 10,
		disjunctiveFacets: ['spice', 'difficulty', 'total_time_band', 'dish_type', 'main_ingredient'], //Can choose multiple
		index: INDEX_NAME,
		filters: 'active=1' //Throws warning, known bug
	}
	var FACETS_ORDER_OF_DISPLAY = ['dish_type', 'main_ingredient', 'spice', 'difficulty', 'total_time_band']
	var FACETS_LABELS = {
		dish_type: 'Dish Type',
		main_ingredient: 'Main Ingredient',
		spice: 'Spice Level',
		difficulty: 'Difficulty',
		total_time_band: 'Total Time'
	}

	var spice_labels = ['Mild', 'Medium', 'Hot']
	var difficulty_labels = ['Quick and Easy', 'Have a Go', 'Showstopper']

	// Client + Helper initialization
	var algolia = algoliasearch(APPLICATION_ID, SEARCH_ONLY_API_KEY)
	var algoliaHelper = algoliasearchHelper(algolia, INDEX_NAME, PARAMS)

	// DOM BINDING
	$searchInput = $('#search-input')
	$main = $('#recipe_results_wrap')
	$hits = $('#hits')
	$facets = $('#facets')
	$pagination = $('#pagination')

	// Hogan templates binding
	var hitTemplate = Hogan.compile($('#hit-template').text())
	var facetTemplate = Hogan.compile($('#facet-template').text())
	var paginationTemplate = Hogan.compile($('#pagination-template').text())
	var noResultsTemplate = Hogan.compile($('#no-results-template').text())

	// SEARCH BINDING

	// Input binding
	$searchInput.on('input propertychange', function (e) {
		var query = e.currentTarget.value
		algoliaHelper.setQuery(query).search()
	})

	// Search errors
	algoliaHelper.on('error', function (error) {
		//console.log(error);
	})

	// Update URL
	algoliaHelper.on('change', function (state) {
		setURLParams()
	})

	// Search results
	algoliaHelper.on('result', function (content, state) {
		renderHits(content)
		renderFacets(content, state)
		renderPagination(content)
		handleNoResults(content)

		changeNumberFont() // Wrap each word in span to style numbers
	})

	// Initial search
	initFromURLParams()
	algoliaHelper.search()

	// EVENTS BINDING
	$(document).on('click', '.toggle-refine', function (e) {
		e.preventDefault()
		algoliaHelper.toggleRefine($(this).data('facet'), $(this).data('value')).search()
	})
	$(document).on('click', '.go-to-page', function (e) {
		e.preventDefault()
		var $this = $(this)
		$('html, body').animate({ scrollTop: $('#recipe_results').offset().top }, function () {
			// Process synchronously to avoid scroll jitter
			algoliaHelper.setCurrentPage(+$this.data('page') - 1).search()
		})
	})
	$(document).on('click', '.clear-all', function (e) {
		e.preventDefault()
		$searchInput.val('')
		algoliaHelper.setQuery('').clearRefinements().search()
	})
	$(document).on('click', '.facet__label', function (e) {
		e.preventDefault()

		if ($(this).hasClass('active')) {
			$(this).attr('aria-expanded', 'false')
		} else {
			$(this).attr('aria-expanded', 'true')
		}

		$(this).toggleClass('active').next('ul').slideToggle('fast')
	})
	$(document).on('click', '#filter-mobile button', function (e) {
		e.preventDefault()

		$('#filter-mobile button, #close-filter').attr('aria-expanded', 'true')

		$('.site-header').css({ display: 'none' }) //Hide nav so that it doesn't overlay
		$('body').addClass('noscroll') //Disable body scrolling
		$('#facets').animate({ left: '0px' }, 200) //Slide in filter panel
	})
	$(document).on('click', '#close-filter', function (e) {
		e.preventDefault()

		$('#filter-mobile button, #close-filter').attr('aria-expanded', 'false')

		$('.site-header').css({ display: 'block' }) //Reset main nav
		$('body').removeClass('noscroll') //Allow body scrolling
		$('html, body').scrollTop($('#recipe_results').offset().top - 64) //Return to top of recipes
		$('#facets').animate({ left: '100%' }, 200, function () {
			$(this).css({ left: '' }) //Remove inline styling in case of screen rotation
		})
	})

	// URL MANAGEMENT
	var URLHistoryTimer = Date.now()
	var URLHistoryThreshold = 700

	window.addEventListener('popstate', function () {
		initFromURLParams()
		algoliaHelper.search()
	})

	// RENDER SEARCH COMPONENTS
	function renderHits(content) {
		for (var i = 0; i < content.hits.length; i++) {
			//Convert difficulty and spice numbers into icon html
			var diff_html = '<span class="sr-only">' + content.hits[i].difficulty + ' out of 3 difficulty</span>',
				spice_html = '<span class="sr-only">' + content.hits[i].spice + ' out of 5 spice levels</span>',
				star_class = '',
				spice_class = '',
				new_spice_scale = (content.hits[i].spice * 3) / 5,
				spice_label

			if (new_spice_scale <= 1) new_spice_scale = 1
			else if (new_spice_scale > 1 && new_spice_scale <= 2) new_spice_scale = 2
			else new_spice_scale = 3

			spice_label = spice_labels[new_spice_scale - 1]

			for (var k = 0; k < 3; k++) {
				spice_class = k < parseInt(new_spice_scale) ? '' : ' icon--chilli--off'
				spice_html += '<span class="icon icon--chilli' + spice_class + '" aria-hidden="true"></span>'
			}

			for (var j = 0; j < 3; j++) {
				star_class = j < parseInt(content.hits[i].difficulty) ? 'icon--star' : 'icon--star-o'
				diff_html += '<span class="icon ' + star_class + '" aria-hidden="true"></span> '
			}

			content.hits[i].resized_image =
				content.hits[i].image !== null
					? content.hits[i].image.replace('/uploads/', '/uploads/400/400/')
					: '/build/images/recipe_placeholder.jpg'
			content.hits[i].difficulty_html = diff_html
			content.hits[i].spice_level_html = spice_html
			content.hits[i].spice_label_html = spice_label
		}

		$hits
			.html(hitTemplate.render(content))
			.promise()
			.done(function () {
				$('#hits .add-elipsis').dotdotdot({
					height: 64,
					fallbackToLetter: true,
					watch: true
				}) //Add truncation
				applySameHeight($('*[data-same-height-parent]'))
			})
	}

	function renderFacets(content, state) {
		var facetsHtml = ''

		for (var facetIndex = 0; facetIndex < FACETS_ORDER_OF_DISPLAY.length; ++facetIndex) {
			var facetName = FACETS_ORDER_OF_DISPLAY[facetIndex]
			var facetResult = content.getFacetByName(facetName)

			if (!facetResult) continue
			var facetContent = {}

			facetContent = {
				facet: facetName,
				title: FACETS_LABELS[facetName],
				values: content.getFacetValues(facetName, { sortBy: ['value:asc', 'name:asc'] }),
				disjunctive: true //All disjunctive
			}

			var i = 0
			if (facetName == 'spice') {
				//Convert spice number into label
				for (i = 0; i < facetContent.values.length; i++) {
					facetContent.title[i].value_label = spice_labels[parseInt(facetContent.values[i].name) - 1]
					facetContent.values[i].value_label = spice_labels[parseInt(facetContent.values[i].name) - 1] //Lookup array
				}
			} else if (facetName == 'difficulty') {
				//Convert difficulty number into label
				for (i = 0; i < facetContent.values.length; i++) {
					facetContent.values[i].value_label = difficulty_labels[parseInt(facetContent.values[i].name) - 1] //Lookup array
				}
			} else if (facetName == 'total_time_band') {
				//Convert time band category into label
				for (i = 0; i < facetContent.values.length; i++) {
					facetContent.values[i].value_label = getTimeLabel(facetContent.values[i].name)
				}
			}

			facetsHtml += facetTemplate.render(facetContent)
		}

		$facets
			.html(facetsHtml + mobile_filter_close)
			.promise()
			.done(function () {
				if (isMobile) {
					$facets.hide().show(0) //Force repaint/redraw (on mobile) to overcome fixed positioning bug
				}
			})
	}

	function renderPagination(content) {
		var pages = []
		if (content.page > 3) {
			pages.push({ current: false, number: 1 })
			pages.push({ current: false, number: '...', disabled: true })
		}
		for (var p = content.page - 3; p < content.page + 3; ++p) {
			if (p < 0 || p >= content.nbPages) continue
			pages.push({ current: content.page === p, number: p + 1 })
		}
		if (content.page + 3 < content.nbPages) {
			pages.push({ current: false, number: '...', disabled: true })
			pages.push({ current: false, number: content.nbPages })
		}
		var pagination = {
			pages: pages,
			prev_page: content.page > 0 ? content.page : false,
			next_page: content.page + 1 < content.nbPages ? content.page + 2 : false
		}
		$pagination.html(paginationTemplate.render(pagination))
	}

	// NO RESULTS
	function handleNoResults(content) {
		if (content.nbHits > 0) {
			$main.removeClass('no-results')
			return
		}
		$main.addClass('no-results')

		var filters = []
		var i
		var j
		for (i in algoliaHelper.state.disjunctiveFacetsRefinements) {
			for (j in algoliaHelper.state.disjunctiveFacetsRefinements[i]) {
				var label_text = ''
				var label_val = algoliaHelper.state.disjunctiveFacetsRefinements[i][j]
				if (i == 'spice') {
					label_text = getSpiceLabel(label_val)
				} else if (i == 'difficulty') {
					label_text = getDifficultyLabel(label_val)
				} else if (i == 'total_time_band') {
					label_text = getTimeLabel(label_val)
				} else {
					label_text = label_val
				}

				filters.push({
					class: 'toggle-refine',
					facet: i,
					facet_value: algoliaHelper.state.disjunctiveFacetsRefinements[i][j],
					label: FACETS_LABELS[i] + ': ',
					label_value: label_text
				})
			}
		}
		$hits.html(noResultsTemplate.render({ query: content.query, filters: filters }))
	}

	// Update and Handle URL Params
	function initFromURLParams() {
		var URLString = window.location.search.slice(1)
		var URLParams = algoliasearchHelper.url.getStateFromQueryString(URLString)
		var stateFromURL = Object.assign({}, PARAMS, URLParams)
		$searchInput.val(stateFromURL.query)
		algoliaHelper.overrideStateWithoutTriggeringChangeEvent(stateFromURL)
	}

	function setURLParams() {
		var trackedParameters = ['attribute:*']
		if (algoliaHelper.state.query.trim() !== '') trackedParameters.push('query')
		if (algoliaHelper.state.page !== 0) trackedParameters.push('page')
		if (algoliaHelper.state.index !== INDEX_NAME) trackedParameters.push('index')

		var URLParams = window.location.search.slice(1)
		var nonAlgoliaURLParams = algoliasearchHelper.url.getUnrecognizedParametersInQueryString(URLParams)
		var nonAlgoliaURLHash = window.location.hash
		var helperParams = algoliaHelper.getStateAsQueryString({
			filters: trackedParameters,
			moreAttributes: nonAlgoliaURLParams
		})
		if (URLParams === helperParams) return

		if ('history' in window && 'pushState' in history) {
			var now = Date.now()
			if (URLHistoryTimer > now) {
				window.history.replaceState(null, '', '?' + helperParams + nonAlgoliaURLHash)
			} else {
				window.history.pushState(null, '', '?' + helperParams + nonAlgoliaURLHash)
			}
			URLHistoryTimer = now + URLHistoryThreshold
		}
	}
}

function getTimeLabel(time_band) {
	switch (time_band) {
		case 'Band 1':
			return '30 mins or less'
		case 'Band 2':
			return '1 hr or less'
		case 'Band 3':
			return '2 hrs or less'
		case 'Band 4':
			return '3 hrs or less'
		case 'Band 5':
			return 'More than 3 hrs'
	}
}
